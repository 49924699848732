<template>
  <div style="background-color: #fff;">
    <!-- 搜索筛选 -->
    <el-form :inline="true" class="user-search flex-between"  @submit.native.prevent>
      <div>
        <templateTitle title="模型管理" :isBack="false"></templateTitle>
      </div>
      <div class="flex">
        <div>
          <el-form-item>
            <el-input
              size="mini"
              v-model="searchVal.name"
              clearable
              @keyup.native.enter="search"
              @change="search"
              placeholder="请输入模型名称"
              type="input"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" plain icon="el-icon-search" @click="search">查询</el-button>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            type="primary"
            plain
            size="mini"
            icon="el-icon-plus"
            @click="showAdd('add')"
            >新建</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <!-- 设备id搜索功能后端无法查询，需后端处理 -->
    <!--列表-->
    <div class="table-padding">
      <el-table
        size="small"
        :data="listData"
        highlight-current-row
        v-loading="loading"
        style="width: 100%"
        height="calc(100vh - 4.375rem)"
        :header-cell-style="{ color: '#212A33', backgroundColor: '#fafafa' }"
        border
        stripe
        :row-style="isSelect"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column type="index" label="序号" width="50" align="center"></el-table-column> -->
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <span>{{ (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="id" label="编号"></el-table-column> -->
        <el-table-column prop="tenantName" label="所属公司"></el-table-column>
        <el-table-column prop="projectName" label="所属项目"></el-table-column>
        <el-table-column prop="name" label="模型名称"></el-table-column>
        <el-table-column prop="url" label="模型地址"></el-table-column>

        <el-table-column align="center" label="操作" width="220">
          <template slot-scope="scope">
            <!-- <el-button type="success" plain size="mini" @click="handleView(scope.row, 'view')">详情</el-button> -->
            <el-button type="primary" plain size="mini" @click="handleEdit(scope.row, 'edit')">编辑</el-button>
            <el-button type="danger" plain size="mini" @click="handleDelete(scope.row, 'del')">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 弹出框 -->
    <formDetail ref="drawer" @refreshTable="refreshTable"></formDetail>
    <!-- 设置权限弹出框发 -->
  </div>
</template>

<script>
import { _pageModelManagement, _deleteModelManagement } from '@/api/modular/system/model'

import Pagination from '@/components/Pagination'
import formDetail from './components/formDetail.vue'
import templateTitle from '@/components/templateTitle.vue'
// ===================================
import moment from 'moment'
export default {
  props: {
    proInfo: {
      type: Object,
      default: function() {
        return {
          /*默认值*/
        }
      }
    },
    admintype: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      showActions: false,
      selectedRows: [], // 当前选中的行
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      listData: [], //表格数据
      loading: false, //是显示加载
      // rules表单验证
      searchVal: {
        name: ''
      },
      proId: this.$store.state.user.proId, //项目上id
      typeId: null,
      tenantArr: [],
      projectArr: []
    }
  },
  // 注册组件
  components: {
    Pagination,
    formDetail,
    templateTitle
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    // admintype proLedgerId 均来自porps
    if (this.admintype == 2) {
      this.proId = this.proInfo.id
    } else if (this.admintype == 3) {
      this.proId = this.$store.state.user.proId
    }
    console.log(this.proId, this.admintype, '========监理日志中打印props传递的对象======')
    this.init()
  },
  mounted() {},
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 点击clear重新请求page
    resetSearch() {
      alert('重置搜索！')
      // this.getPage()
    },
    init() {
      this.getPage()
      // this.getProjectArr()
      console.log(this.proId, '6666666666')
    },
    showAdd(type) {
      this.$refs.drawer.add(type, this.proId, this.projectArr)
    },
    handleEdit(value, type) {
      this.$refs.drawer.edit(value, type, this.proId, this.projectArr)
    },
    handleView(value, type) {
      this.$refs.drawer.view(value, type, this.proId, this.projectArr)
    },
    // 获取page列表
    getPage(searchDara) {
      const { currentPage, pageSize, total } = this.pageparm
      return
      this.loading = true

      const params = {
        ProjectId: this.proId,
        pageNo: currentPage,
        pageSize: pageSize,
        searchBeginTime: '',
        searchEndTime: '',
        sortField: '',
        sortOrder: '',
        descStr: '',
        searchParameters: [],
        ...this.searchVal
      }
      _pageModelManagement(params).then(res => {
        if (res.code == 200) {
          console.log('分页查询tab3', res)
          this.listData = res.data.rows
          this.pageparm.total = res.data.totalRows
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      })
    },
    refreshTable() {
      this.getPage()
    },

    // 选择select
    handleSelectionChange(selection) {
      // console.log(selection);//点击的那行数据arr
      this.selectedRows = selection
      this.showActions = selection.length > 0
    },
    isSelect({ row }) {
      const checkIdList = this.selectedRows.map(item => item.data1)
      if (checkIdList.includes(row.data1)) {
        return {
          color: '#e17009'
        }
      }
    },

    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
    // 搜索事件
    search() {
      this.getPage()
    },
    // 删除逻辑
    handleDelete(row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = { id: row.id }
          _deleteModelManagement(params).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.refreshTable()
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
</style>
